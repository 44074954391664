import request from "../utils/request";

// 查询角色（超级管理员）
export function search_role(data) {
  return request({
    url: "search_role",
    method: "post",
    data: data,
  });
}
// 增加角色（超级管理员）
export function add_role(data) {
  return request({
    url: "add_role",
    method: "post",
    data: data,
  });
}
// 删除角色
export function delete_role(data) {
  return request({
    url: "delete_role",
    method: "post",
    data: data,
  });
}
// 修改角色
export function update_role(data) {
  return request({
    url: "update_role",
    method: "post",
    data: data,
  });
}
// 查询权限（超级管理员）
export function search_all_permission(data) {
  return request({
    url: "search_all_permission",
    method: "post",
    data: data,
  });
}
// 查询用户（管理员）
export function searchUser(data) {
  return request({
    url: "search/User",
    method: "post",
    data: data,
  });
}
// 修改用户（管理员）
export function admin_update_user(data) {
  return request({
    url: "admin_update_user",
    method: "post",
    data: data,
  });
}
// 添加用户（管理员）
export function admin_register(data) {
  return request({
    url: "admin_register",
    method: "post",
    data: data,
  });
}
// 删除用户（管理员）
export function delete_user(data) {
  return request({
    url: "delete_user",
    method: "post",
    data: data,
  });
}
// 添加模板（管理员）
export function add_model(data) {
  return request({
    url: "add_model",
    method: "post",
    data: data,
  });
}
// 查看模板（管理员）
export function search_model(data) {
  return request({
    url: "search_model",
    method: "post",
    data: data,
  });
}
// 修改模板（管理员）
export function update_model(data) {
  return request({
    url: "update_model",
    method: "post",
    data: data,
  });
}
//上传模板图片（管理员）
export function image_upload(data) {
  return request({
    url: "image_upload",
    method: "post",
    data: data,
  });
}
//生成推荐信（管理员）
export function generate_letter(data) {
  return request({
    url: "generate_letter",
    method: "post",
    data: data,
  });
}
//查看推荐信（管理员）
export function search_letter(data) {
  return request({
    url: "search_letter",
    method: "post",
    data: data,
  });
}
//查看推荐信（管理员）
export function search_own_letter(data) {
  return request({
    url: "search_own_letter",
    method: "post",
    data: data,
  });
}
//修改模板图片（管理员）
export function update_model_image(id) {
  return request({
    url: "/update_model_image/" + id,
    method: "post",
  });
}
//上传推荐信（管理员）
export function upload_letter(data) {
  return request({
    url: "/upload_letter/",
    method: "post",
    data: data,
  });
}
// 删除模板（管理员）
export function delete_model(id) {
  return request({
    url: "/delete_model/" + id,
    method: "post",
  });
}
// 删除推荐信（管理员）
export function delete_letter(data) {
  return request({
    url: "/delete_letter",
    method: "post",
    data: data,
  });
}

// 审核表单（管理员）
export function check_apply(data) {
  return request({
    url: "check_apply",
    method: "post",
    data: data,
  });
}
// 修改表单（管理员）
export function update_submit_form(data) {
  return request({
    url: "update_submit_form",
    method: "post",
    data: data,
  });
}
// 查看表单（管理员）
export function search_submit_form(data) {
  return request({
    url: "search_submit_form",
    method: "post",
    data: data,
  });
}
// 查看表单（管理员）
export function search_own_form(data) {
  return request({
    url: "search_own_form",
    method: "post",
    data: data,
  });
}
// 删除表单（管理员）
export function delete_submit_form(data) {
  return request({
    url: "delete_submit_form",
    method: "post",
    data: data,
  });
}
// 用户提交表单
export function submit_form(data) {
  return request({
    url: "submit_form",
    method: "post",
    data: data,
  });
}
// 获取字体列表
export function search_font_type(data) {
  return request({
    url: "search_font_type",
    method: "post",
    data: data,
  });
}
//获取教师列表
export function teacher_list(data) {
  return request({
    url: "teacher_list",
    method: "post",
    data: data,
  });
}
//查询推荐码
export function referral_code(data) {
  return request({
    url: "search_referral_code_own",
    method: "post",
    data: data,
  });
}
// 重置密码
export function reset_passwords(data) {
  return request({
    url: "reset_passwords",
    method: "post",
    data: data,
  });
}
