<template>
  <div class="personal">
    <!-- <el-row>
      <el-col :span="6">
        <el-input
          size="mini"
          v-model="RecommendaParams.keywords"
          @keyup.enter.native="getsearchRecommenda"
          clearable
          placeholder="请输入推荐信关键字"
        ></el-input>
      </el-col>
      <el-col :span="2" style="padding-left: 20px">
        <el-button size="mini" type="primary" @click="getsearchRecommenda"
          >搜 索</el-button
        >
      </el-col>
    </el-row> -->

    <el-table :data="tableData" style="width: 100%; margin-top: 20px">
      <el-table-column prop="realname" label="真实姓名"> </el-table-column>
      <el-table-column prop="username" label="手机号"> </el-table-column>
      <el-table-column prop="" label="委托老师">
        <template slot-scope="scope">
          {{ TranslationRealname(scope.row.teacher_id) }}
        </template>
      </el-table-column>
      <el-table-column prop="apply_school" label="申请学校"> </el-table-column>
      <el-table-column prop="specialty" label="申请的专业"> </el-table-column>
      <!-- <el-table-column prop="letter_url" label="推荐信地址"> </el-table-column>
      <el-table-column prop="file_type" label="文件类型"> </el-table-column> -->
      <el-table-column prop="letter_status" label="推荐信状态">
        <template slot-scope="scope">
          {{ scope.row.letter_status == "0" ? "审批中" : "已生成" || "" }}
        </template>
      </el-table-column>
      <el-table-column prop="address" label="下载" width="230px">
        <template slot-scope="scope">
          <el-button
            type="success"
            size="mini"
            @click="download(scope.row.letter_url_pdf, 'pdf')"
            plain
            >PDF</el-button
          >
          <el-button
            type="success"
            size="mini"
            @click="download(scope.row.letter_url_word, 'word')"
            plain
            >WORD</el-button
          >
          <el-button
            type="success"
            size="mini"
            @click="preview(scope.row.letter_url_pdf)"
            plain
            >预览</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination
      :total="total"
      :page.sync="RecommendaParams.pageNum"
      :limit.sync="RecommendaParams.pageSize"
      @pagination="getsearchRecommenda"
    />
  </div>
</template>

<script>
import {
  search_own_letter,
  delete_letter,
  teacher_list
} from "../../api/Administration.js";
export default {
  data() {
    return {
      RecommendaParams: {
        keywords: "",
        pageNum: 1,
        pageSize: 10
      },
      tableData: [],
      teacherList: [],
      total: 0
    };
  },
  created() {
    this.getsearchRecommenda();
    this.getsearchteacher();
  },
  watch: {
    $route(to, from) {
      if (to.fullPath == "/personal") {
        this.getsearchRecommenda();
        this.getsearchteacher();
      }
    }
  },
  methods: {
    // 获取教师数据列表
    getsearchteacher() {
      teacher_list().then(res => {
        this.teacherList = res.data;
      });
    },
    //展示老师字段
    TranslationRealname(id) {
      var RealnaneName = "";
      this.teacherList.forEach(item => {
        if (item.teacher_id == id) {
          RealnaneName = item.realname;
        }
      });
      return RealnaneName;
    },
    // 获取推荐信列表数据
    getsearchRecommenda() {
      search_own_letter(this.RecommendaParams).then(res => {
        this.tableData = res.data;
        this.total = res.data.length;
      });
    },
    // 下载文件
    download(url, type) {
      if (type == "pdf") {
        window.location.href = url + "/1";
        return;
      } else {
        window.location.href = url + "/1";
        return;
      }
    },
    // 预览
    preview(url) {
      window.open(url + "/0");
    }
  }
};
</script>
<style>
.personal {
  width: 80%;
  margin: 0 auto;
}
</style>
